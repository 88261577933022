import { Fade, Zoom } from 'react-awesome-reveal';
import '../Css/Feature.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


export default function Faeture() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };
    return (
        <div className="feature">
            <div className="main">
                <div className="heading">
                    <Zoom>
                        <h1>{t('main Features')}</h1>
                    </Zoom>
                </div>
            </div>
            <div className="dis">
                <div className="list-items">
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/blutooth.png" alt="" />

                        </div>
                        <div className="text">
                            <h3>
                                {t('main 1')}
                            </h3>
                        </div>
                    </div>
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/space.png" alt="" />
                        </div>
                        <div className="text">
                            <h3>
                            {t('main 2')}
                            </h3>
                        </div>
                    </div>
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/multicolor.png" alt="" />
                        </div>
                        <div className="text">
                            <h3>
                            {t('main 3')}
                            </h3>
                        </div>
                    </div>
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/speaker.png" alt="" />
                        </div>
                        <div className="text">
                            <h3>
                            {t('main 4')}
                            </h3>
                        </div>
                    </div>
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/speaker.png" alt="" />
                        </div>
                        <div className="text">
                            <h3>
                            {t('main 5')}
                            </h3>
                        </div>
                    </div>
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/phone.png" alt="" />
                        </div>
                        <div className="text">
                            <h3>
                            {t('main 6')}
                            </h3>
                        </div>
                    </div>
                    <div className="item">
                        <div className="image-part">
                            <img src="../assets/feture/fet/mobile.png" alt="" />
                        </div>
                        <div className="text">
                            <h3>
                            {t('main 7')}
                            </h3>
                        </div>
                    </div>
                </div>

            </div>

            <div className="border">
                <div className="image">
                    <img src="../assets/lamb/landing/light/08.png" />
                </div>
            </div>



        </div>
    )
}
