import { Fade, Zoom } from 'react-awesome-reveal';
import '../Css/Quran.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Quarn() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };
  return (
    <>
      <div className="main-content">
        <div className='dis'>
          <Zoom>
            <h1 style={{ textAlign: language === 'ar' ? 'center' : 'center' }}>
              {t('mid heading1')} <br /> <span> {t('mid heading2')}</span>
            </h1>
          </Zoom>
          <div className="itms-list">
            <Fade direction={'right'} duration={500} delay={100}>
              <div className="item">
                <div className="logo">
                  <img src="../assets/logo/01.svg" alt="" />
                </div>
                <h3 style={{ textAlign: language === 'ar' ? 'center' : 'center' }}>{t('mid 1')}</h3>
              </div>
            </Fade>
            <Fade direction={'right'} duration={600} delay={300}>
              <div className="item">
                <div className="logo">
                  <img src="../assets/logo/02.svg" alt="" />
                </div>
                <h3 style={{ textAlign: language === 'ar' ? 'center' : 'center' }}>{t('mid 2')}</h3>
              </div>
            </Fade>
            <Fade direction={'right'} duration={700} delay={500}>
              <div className="item">
                <div className="logo">
                  <img src="../assets/logo/03.svg" alt="" />
                </div>
                <h3 style={{ textAlign: language === 'ar' ? 'center' : 'center' }}>{t('mid 3')}</h3>
              </div>
            </Fade>
            <Fade direction={'right'} duration={800} delay={700}>
              <div className="item">
                <div className="logo">
                  <img src="../assets/logo/04.svg" alt="" />
                </div>
                <h3 style={{ textAlign: language === 'ar' ? 'center' : 'center' }}>{t('mid 4')}</h3>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="quaran">
        <div className="top-clouds">
          <img src="../assets/lamb/landing/clouds-R.png" alt="" />

        </div>
        <div className="top">

          <div className="leaf">
            <Fade direction={'down'} duration={500} delay={100}>
              <img src="../assets/Quran/tree.png" alt="" />
            </Fade>
          </div>
          <div className="lamb">
            <Fade direction={'down'} duration={500} delay={100}>
              <img src="../assets/Quran/lamp.png" alt="" />
            </Fade>
          </div>

        </div>
        <div className="main">
          <div className="dis">
            <div className="details" style={{
                alignitems: i18n.language === 'ar' ? 'start' : 'end',
              }}>

              <ul style={{
                textAlign: i18n.language === 'ar' ? 'right' : 'left',
                textAlign: i18n.language === 'ar' ? 'right' : 'left',
                listStyle: 'square',
                direction: i18n.language === 'ar' ? 'rtl' : 'ltr'
              }}>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 7')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 8')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 9')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 10')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 11')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 12')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 13')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 14')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 15')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 16')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 17')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 18')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 19')}
                </li>

              </ul>
              <ul style={{
                textAlign: i18n.language === 'ar' ? 'right' : 'left',
                listStyle: 'square',
                direction: i18n.language === 'ar' ? 'rtl' : 'ltr'
              }}>

                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 1')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 2')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 3')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 4')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 5')}
                </li>
                <li style={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                  paddingLeft: '1em'
                }}>
                  {t('quran li 6')}
                </li>

              </ul>

            </div>

          </div>
          <div className="stage">
            <img src="../assets/Quran/stage.png" alt="" />
          </div>

        </div>
        <div className="border">
          <img src="../assets/feture/top.png" alt="" />

        </div>

      </div>
    </>
  )
}
