import './App.css';
import RouterFunction from './routes/RouterFunction.js'
import React from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection: {
      order: [
        'cookie',
        'htmlTag',
        'localStorage',
        'sessionStorage',
        'navigator',
        'path',
        'subdomain'
      ],
      cache: ["cookie"],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    }
  });



function App() {
  return (
    <div className="App">
      <RouterFunction />
    </div>
  );
}

export default App;
