
import HeroSlider from '../Components/HeroSlider.js';
import '../Css/style.css';
import Quarn from '../Components/Quarn.js';
import Faeture from '../Components/Faeture.js';
import WhatsApp from '../Components/WhatsApp.js'


export default function Home() {

  return (
    <>

      <section>
        <WhatsApp />
        <section class="parallax-1">
          <HeroSlider />
        </section>
        <section class="parallax-2">
          <div class="parallax-inner">
            <Quarn />
          </div>
        </section>
        <div className="fill">

        </div>
        <section class="parallax-3">
          <div class="parallax-inner">
            <Faeture />
          </div>
        </section>
      </section>
    </>
  )
}
