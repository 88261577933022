import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../Css/Hero.css';

export default function HeroSlider() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    useEffect(() => {
        const showSlider = (type) => {
            const nextButton = document.getElementById('next');
            const prevButton = document.getElementById('prev');
            const carousel = document.querySelector('.carousel');
            const listHTML = document.querySelector('.carousel .list');

            let unAcceptClick;

            nextButton.style.pointerEvents = 'none';
            prevButton.style.pointerEvents = 'none';

            carousel.classList.remove('next', 'prev');
            const items = document.querySelectorAll('.carousel .list .item');

            if (type === 'next') {
                listHTML.appendChild(items[0]);
                carousel.classList.add('next');
            } else {
                listHTML.prepend(items[items.length - 1]);
                carousel.classList.add('prev');
            }

            clearTimeout(unAcceptClick);
            unAcceptClick = setTimeout(() => {
                nextButton.style.pointerEvents = 'auto';
                prevButton.style.pointerEvents = 'auto';
            }, 2000);
        };

        const nextButton = document.getElementById('next');
        const prevButton = document.getElementById('prev');

        nextButton.onclick = () => showSlider('next');
        prevButton.onclick = () => showSlider('prev');

        const sliderInterval = setInterval(() => {
            showSlider('next'); // Automatically run every 3000ms
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
            nextButton.onclick = null;
            prevButton.onclick = null;
        };
    }, []);
    return (
        <>
            <section >

                <div class="carousel">
                    <div className="price">
                        <div className="top-border">
                            <div className="top-inner">
                                <div className="border">

                                    <div className="main">
                                        <h3>{t('Ramadan')}</h3>
                                        <h5 style={{ textAlign: i18n.language === 'ar' ? 'left' : 'right' }}>{t('price was')}</h5>
                                        <h1 style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>{t('price')}</h1>
                                        {/* <h2 style={{
                                            textAlign: i18n.language === 'ar' ? 'right' : 'left',
                                            fontWeight:i18n.language === 'ar' ? 'bold' : '300', // Font size based on language
                                            fontFamily: i18n.language === 'ar' ? 'Berkshire Swash, sans-serif' : 'Poppins,sans-serif' // Font family based on language
                                        }}>{t('price dis')}</h2> */}
                                        <h2 style={{
                                             textAlign: i18n.language === 'ar' ? 'right' : 'left',
                                             fontWeight:i18n.language === 'ar' ? 'bold' : '300', // Font size based on language
                                             fontFamily: i18n.language === 'ar' ? 'Berkshire Swash, sans-serif' : 'Poppins,sans-serif' // Font family based on language
                                        }}>{t('price dis 1')}</h2>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="bg-frame" >
                        <img src="../assets/lamb/landing/bg/fram.png" alt="" />
                        <div class="bg-stage">
                            <img src="../assets/lamb/landing/bg/stage.png" alt="" />
                        </div>
                    </div>
                    <div class="list">
                        <div class="item">
                            <img src="../assets/lamb/landing/light/08.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 01')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 01 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/02.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 02')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 02 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/03.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 03')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 03 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/04.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 04')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 04 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/05.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 05')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 05 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/06.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 06')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 06 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/07.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 07')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 06 des')}
                                </div> */}
                            </div>
                        </div>
                        <div class="item">
                            <img src="../assets/lamb/landing/light/01.png" />
                            <div class="introduce">
                                <div class="topic" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 08')}
                                </div>
                                {/* <div class="des" style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>
                                    {t('Slide 06 des')}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="more-details-part">
                        <a href="">
                            <div className="more-details">
                                <FontAwesomeIcon icon={faWhatsapp} className='whatsapp' />
                                <h2>More Details</h2>
                            </div>
                        </a>
                    </div> */}
                    <div class="arrows">
                        <button id="prev">B</button>
                        <button id="next">N</button>
                        <button id="back">Back To Main</button>
                    </div>

                    <div className='clouds'>
                        <img src="../assets/lamb/landing/clouds-U.png" alt="" />
                    </div>



                </div>


            </section>
        </>
    )
}
