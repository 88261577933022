import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../Css/WhatsApp.css';
import '../Css/Popup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function WhatsApp() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    }



    function validateForm() {
        var name = document.getElementById('name').value;
        var email = document.getElementById('email').value;
        var subject = document.getElementById('subject').value;
        var message = document.getElementById('message').value;

        if (name === "" || email === "" || subject === "" || message === "") {
            alert("Please fill in all fields.");
            return false;
        }
        return true;
    }


    const [popup, setPop] = useState(false);
    const [popup1, setPop1] = useState(false);
    const handleClickOpen = () => {
        setPop(!popup);
    }

    const closePopup = () => {
        setPop(false);
        setPop1(false)
    }


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_o2ejudn', 'template_k7v975d', form.current, '8DAAX1_j7a2dIwhLC')
            .then((result) => {
                console.log(result.text);
                setPop(false);
                setPop1(true);
            }, (error) => {
                console.log(error.text);
                setPop(false);
                setPop1(false);
            });
    };


    const openWhatsApp = () => {
        window.open("https://api.whatsapp.com/send?phone=971566978089", "_blank");
    }

    return (
        <>
            <div className='whatsApp'>
                <div className="main">
                    <button className="logo-ordernow" onClick={handleClickOpen}>
                        <FontAwesomeIcon icon={faCartShopping} className="shopping-icon " />
                    </button>
                    <button className="logo" onClick={openWhatsApp}>
                        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
                    </button>
                </div>
            </div>

            {popup ?
                <div className="popup">
                    <div className="main">
                        <div className="logo">
                            <img src="../assets/logo/logo.png" alt="" />
                        </div>
                        <div className="form">
                            <div className="heading">
                                <h1 style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}> {t('order now')}</h1>
                            </div>

                            <form ref={form} onSubmit={sendEmail}>
                                <label htmlFor="name" className='label' style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>   {t('name')}</label>
                                <input type="text" id="name" name="cust_name" placeholder={t('enter your name')} required style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }} />

                                <label htmlFor="phone" className='label' style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>{t('phone number')}</label>
                                <input type="text" id="phone" name="cust_phone" placeholder={t('enter your phone number')} required style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }} />

                                <label htmlFor="email" className='label' style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }}>{t('address')}</label>
                                <input type="text" id="email" name="cust_email" placeholder={t('enter your address')} required style={{ textAlign: i18n.language === 'ar' ? 'right' : 'left' }} />
                                <div className="buttons">
                                    <input type="submit" value={t('order')} className='order-btn' />
                                    <input type="button" value={t('return home')} onClick={closePopup} className='return-btn' />
                                </div>
                                {
                                    console.log("pop" + popup)
                                }
                                {
                                    console.log("pop1" + popup1)
                                }
                            </form>
                        </div>
                        <img src="../assets/lamb/landing/light/02.png" className='lamp' />
                    </div>
                </div> : ""
            }

            {popup1 ?
                <div className="popup1">
                    <div className="main">
                        <div className="lamp">
                            <img src="../assets/lamb/landing/light/03.png" />
                        </div>
                        <div className="thanks">
                            <h1>    
                                 {t('thank you')}
                                 </h1>
                            <h2>
                                {t('thank you massege')}
                            </h2>
                            <input type="button" value={t('return home')} onClick={closePopup} className='return-btn' />

                        </div>

                    </div>

                </div> : ""
            }
        </>
    )
}
