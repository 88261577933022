import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../Components/ScrollToTap.js';
import Header from '../Components/Header.js';
import Footer from '../Components/Footer.js'
import Home from '../Pages/Home.js';

export default function RouterFunction() {
    return (
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
          </Routes>
     
          <Footer />
        </Router>
  )
}
