import '../Css/Footer.css';
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faSnapchat, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';



export default function Footer() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };
  return (
    <>

      <div className='footer'>
        <div className="main">
          <div className="logo">
            <img src="../assets/logo/logo11.png" alt="" />
          </div>
          <div className="socil-media">
            <a href="https://www.instagram.com/mtajerna_store/">
              <div className="icons">
                <FontAwesomeIcon icon={faInstagram} className='icon' />
              </div>
            </a>
            <a href="https://www.tiktok.com/@mtajerna_store?lang=en">
              <div className="icons">
                <FontAwesomeIcon icon={faTiktok} className='icon' />
              </div>
            </a>
            <a href="https://profile.snapchat.com/b7ed3384-14e2-4726-92f5-4cf14ae1f83b/profiles/d4a495db-42a1-4f96-9047-e9dbb2d7a147/details/public-stories?ref_aid=300a75b8-c029-492d-98d1-8eb55c33b272">
              <div className="icons">
                <FontAwesomeIcon icon={faSnapchat} className='icon' />
              </div>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556700758112">
              <div className="icons">
                <FontAwesomeIcon icon={faFacebookF} className='icon' />
              </div>
            </a>
          </div>
        </div>

      </div>
      <div className="footer-bottom">
        <div className="main">
          <p p className={i18n.language === 'ar' ? 'arb' : ''}>
            {t('footer')}
          </p>
        </div>
      </div>
    </>




  )
}
