import '../Css/Header.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Assuming you are using react-i18next for internationalization

function LanguageToggle() {
  const { i18n } = useTranslation(); // Hook to access i18n functions
  const [isChecked, setIsChecked] = useState(false); // State to track the checkbox's checked state

  // Function to handle language toggle
  const toggleLanguage = () => {
    setIsChecked(prevState => !prevState); // Toggle the checked state
    const newLanguage = isChecked ? 'ar' : 'en'; // Determine the language based on the checked state
    i18n.changeLanguage(newLanguage); // Change the language using i18n

  
   


    
  };

  return (
    <div className='header'>
      <div className="main">

        <div className="logo">
          <img src="../assets/logo/logo11.png" alt="" />
        </div>
        <div className="language">
          
          <button onClick={() => i18n.changeLanguage('ar')}>
            ar
          </button>
          <button onClick={() => i18n.changeLanguage('en')}>
            en
          </button>
        </div>

      </div>

    </div>
  );
}

export default LanguageToggle;
